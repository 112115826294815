import { ReactNode } from 'react';
import { type Dayjs } from 'dayjs';

import {
  type InputProps,
  type SelectProps,
  type TimeRangePickerProps,
  type ButtonProps,
  Flex,
  Switch,
} from 'antd';

import {
  type SearchControlProps,
  FilterTypes,
  RangePicker,
  SearchControl,
  FilterConstructorDrawer,
} from '@entities';

import { DropdownSelect, type DropdownSelectProps, Select } from '@components';

import { DATE_ISO_FORMAT, getDateDefault } from '@utils';

import * as S from './styled';

export type ControlType =
  | 'divider'
  | 'search'
  | 'range-picker'
  | 'list-types'
  | 'list-contacts'
  | 'list-categories'
  | 'list-banks'
  | 'list-projects'
  | 'switch'
  | 'select'
  | 'module-columns-config'
  | 'module-filter'
  | 'module-sort'
  | 'custom-component';

export type ControlParams =
  | InputProps
  | TimeRangePickerProps
  | SelectProps
  | DropdownSelectProps;

export type FilterResetParams = { IS_RESET?: boolean };

export type FilterSearchParams = Record<string, unknown>;

export type FilterControl = {
  type: ControlType;
  formName?: string;
  label?: string | null;
  params?: ControlParams;
  buttonParams?: ButtonProps;
  component?: ReactNode;
  controls?: FilterControl[]; // Only for modules
};

export type FilterControlConfig = {
  initialParams?: FilterSearchParams;
  moduleParams?: DropdownSelectProps;
  onControlChange?: (value: any) => void;
} & FilterControl;

const FilterConstructorControls = ({
  type,
  formName,
  label = null,
  params = {},
  controls,
  component,
  initialParams,
  moduleParams,
  buttonParams,
  onControlChange,
}: FilterControlConfig) => {
  switch (type) {
    case 'divider':
      return <S.Divider />;

    case 'search':
      return (
        <S.FormControl name={formName}>
          <SearchControl
            onChange={(e) => onControlChange?.(e.target.value)}
            {...(params as SearchControlProps)}
          />
        </S.FormControl>
      );

    case 'range-picker':
      return (
        <S.FormControl label={label} name={formName}>
          <RangePicker
            onChange={(date: [Dayjs, Dayjs] | null | any) =>
              onControlChange?.(
                date
                  ? {
                      dateFrom: getDateDefault(date[0]!, DATE_ISO_FORMAT),
                      dateTo: getDateDefault(date[1]!, DATE_ISO_FORMAT),
                    }
                  : null
              )
            }
            {...(params as TimeRangePickerProps)}
          />
        </S.FormControl>
      );

    case 'list-types':
      return (
        <S.FormControl label={label} name={formName}>
          <FilterTypes
            placeholder="Type"
            onChange={onControlChange}
            {...(params as SelectProps)}
          />
        </S.FormControl>
      );

    case 'switch':
      return (
        <Flex gap={12}>
          <S.FormControl name={formName} noStyle>
            <Switch onChange={onControlChange} />
          </S.FormControl>

          {label && <S.SwitchLabel>{label}</S.SwitchLabel>}
        </Flex>
      );

    case 'select':
      return (
        <S.FormControl label={label} name={formName}>
          <Select
            size="large"
            onChange={onControlChange}
            {...(params as SelectProps)}
          />
        </S.FormControl>
      );

    case 'module-columns-config':
      return (
        <DropdownSelect {...(moduleParams || ({} as DropdownSelectProps))} />
      );

    case 'module-filter':
      return (
        <FilterConstructorDrawer
          initialParams={initialParams}
          controls={controls || []}
          buttonParams={buttonParams}
          onSubmit={onControlChange}
        />
      );

    case 'custom-component':
      return component;

    default:
      return null;
  }
};

export default FilterConstructorControls;
