import { Params$AdminDocumentController_findOneById } from '@api-client/generated/client';

import { Schemas } from '@api-client/generated/types';

import { QueryOptions, useApiClient } from '@hooks';

type Params = Params$AdminDocumentController_findOneById;

type DocumentProps = {
  params: Params['parameter'];
  config?: QueryOptions<Schemas.Document>;
};

const useGetDocumentById = ({ params, config }: DocumentProps) => {
  const { data: document, ...rest } = useApiClient<Params, Schemas.Document>({
    method: 'AdminDocumentController_findOneById',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    document,
    ...rest,
  };
};

export default useGetDocumentById;
