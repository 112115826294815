import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Panel = styled(Flex)<FlexProps>`
  height: 20px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.text400};
  font-size: 10px;
  text-align: center;
  margin: 22px 30px;
`;
