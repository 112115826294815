import { Params$AdminBobContactController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type BobContact = Schemas.BobContact;
type Params = Params$AdminBobContactController_findAll;

type BobContactsProps = {
  params: Params['parameter'];
  config?: QueryOptions<BobContact[]>;
};

const useGetBobContacts = ({ params, config }: BobContactsProps) => {
  const { data: contacts, ...rest } = useApiClient<Params, BobContact[]>({
    method: 'AdminBobContactController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    contacts: contacts || [],
    ...rest,
  };
};

export default useGetBobContacts;
