import { FC } from 'react';

import { type ModalProps, Modal } from 'antd';

import { FilePreview } from '@entities';

import { Schemas } from '@api-client/generated/types';

type TaskFile = Schemas.TaskFile;

type ChatModalFileViewerProps = {
  file: TaskFile;
  onCancel: () => void;
} & ModalProps;

const ChatModalFileViewer: FC<ChatModalFileViewerProps> = ({
  open,
  file,
  onCancel,
  ...rest
}) => (
  <Modal
    width={557}
    open={open}
    title={file?.fileName || 'File'}
    footer={null}
    onCancel={onCancel}
    destroyOnClose
    centered
    {...rest}
  >
    <FilePreview file={file} />
  </Modal>
);

export default ChatModalFileViewer;
