import styled from '@emotion/styled';

import { Input } from 'antd';

import { type SearchControlProps } from './SearchControl';

export const Control = styled(Input)<SearchControlProps>`
  border-color: transparent;

  && {
    width: ${({ width, fitWidth }) =>
      fitWidth ? '100%' : `${width || 285}px`};
    background-color: ${({ theme, isInversedTheme }) =>
      isInversedTheme ? theme.colors.white : theme.colors.bgGrayLight};
    border-radius: 20px;
    box-shadow: none;
    padding-inline: 24px;

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ theme, isInversedTheme }) =>
        isInversedTheme ? theme.colors.white : theme.colors.bgGrayLight};
    }

    .ui-input-prefix {
      margin-inline-end: 10px;
    }
  }
`;
