import { FC, useState } from 'react';

import { Flex } from 'antd';

import * as S from './styled';

type RadioControl = {
  value: string;
  title: string;
};

export type RadioGroupProps = {
  controls: RadioControl[];
  value?: RadioControl['value'];
  vertical?: boolean;
  onChange?: (value: string) => void;
};

const RadioGroup: FC<RadioGroupProps> = ({
  vertical = false,
  value,
  controls,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <Flex align="center" gap={8} vertical={vertical}>
      {controls.map(({ value, ...control }) => (
        <S.Control
          key={value}
          onClick={() => handleSelect(value)}
          selected={selectedValue === value}
        >
          <S.ControlIcon selected={selectedValue === value} />
          <S.Title>{control.title}</S.Title>
        </S.Control>
      ))}
    </Flex>
  );
};

export default RadioGroup;
