import styled from '@emotion/styled';

import { Flex, type FlexProps } from 'antd';

type ItemProps = {
  isSelected: boolean;
};

type MarkerUnit = 'chats' | 'tasks';

export const Item = styled(Flex)<FlexProps & ItemProps>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.bgDarkGrey : theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  padding: 10px 16px;

  &:first-child {
    border-radius: 10px 10px 0 0;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  &:only-child {
    border-radius: 10px;
  }
`;

export const Name = styled.div`
  font-weight: 600;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 10px;
`;

export const Resolved = styled.div`
  color: ${({ theme }) => theme.colors.text200};
  font-size: 12px;
`;

export const Active = styled.div`
  color: ${({ theme }) => theme.colors.success};
  font-size: 12px;
  font-weight: 600;
`;

export const UnreadMarker = styled.div<{ type: MarkerUnit }>`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
`;
