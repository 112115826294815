import { FC } from 'react';

import { Dropdown } from 'antd';

import { IconMoreVertical } from '@assets';

import * as S from './styled';

type ChatMessagesMenuActionsProps = {
  onRemove: () => void;
};

const ChatMessagesMenuActions: FC<ChatMessagesMenuActionsProps> = ({
  onRemove,
}) => (
  <Dropdown
    menu={{
      items: [
        {
          key: 'remove',
          label: (
            <S.Item type="danger" onClick={onRemove}>
              Delete
            </S.Item>
          ),
        },
      ],
    }}
    placement="bottomRight"
    trigger={['click']}
  >
    <S.Button>
      <IconMoreVertical />
    </S.Button>
  </Dropdown>
);

export default ChatMessagesMenuActions;
