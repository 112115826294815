import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { List } from 'antd';

import { Scrollbar } from '@components';
import { getDateRelative } from '@utils';

import ChatSidebarItem from '../ChatSidebarItem';

import { Schemas } from '@api-client/generated/types';

type ChatSidebarListProps = {
  companies: Schemas.Company[];
  loading: boolean;
};

const ChatSidebarList: FC<ChatSidebarListProps> = ({ companies, loading }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  const handleNavigateToCompany = (id: string) => {
    if (companyId !== id) {
      navigate(`/chat-tasks/${id}/chats`);
    }
  };

  return (
    <Scrollbar height="calc(100vh - 275px)" withoutPadding isHidden>
      <List
        dataSource={companies}
        renderItem={(item) => (
          <ChatSidebarItem
            name={item.name}
            date={getDateRelative(new Date())}
            countActive={0}
            isSelected={item.id === companyId}
            onClick={() => handleNavigateToCompany(item.id)}
          />
        )}
        loading={loading}
        locale={{ emptyText: <div /> }}
      />
    </Scrollbar>
  );
};

export default ChatSidebarList;
