import { Params$AdminCompanyController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { QueryOptions, useApiClient } from '@hooks';

type Params = Params$AdminCompanyController_findAll;

type CompaniesListProps = {
  params: Params['parameter'];
  config?: QueryOptions<Schemas.Company[]>;
};

const useGetCompaniesList = ({ params, config }: CompaniesListProps) => {
  const {
    data: companies,
    loading,
    refetch,
  } = useApiClient<Params, Schemas.Company[]>({
    method: 'AdminCompanyController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    companies: companies || [],
    loading,
    refetch,
  };
};

export default useGetCompaniesList;
