import { FC, ReactNode } from 'react';

import { Flex } from 'antd';

import * as S from './styled';

type ChatPanelHeaderProps = {
  title: string;
  actions: ReactNode;
};

const ChatPanelHeader: FC<ChatPanelHeaderProps> = ({ title, actions }) => (
  <S.Header align="center" justify="space-between" gap={16}>
    <S.Title>{title}</S.Title>

    <Flex gap={16} align="center">
      {actions}
    </Flex>
  </S.Header>
);

export default ChatPanelHeader;
