import { FC, useState } from 'react';

import { Flex } from 'antd';

import { TransactionsModal, DocumentsModal, LegalModal } from '@entities';

import ChatModalViewFiles from '../../modals/ChatModalViewFiles';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type ChatActionsProps = {
  companyId: string;
  companyName: string;
  details: Schemas.Task;
};

const ChatActions: FC<ChatActionsProps> = ({
  companyId,
  companyName,
  details,
}) => {
  const [transactionsModalVisible, setTransactionsModalVisible] =
    useState(false);
  const [documentsModalVisible, setDocumentsModalVisible] = useState(false);
  const [legalModalVisible, setLegalModalVisible] = useState(false);
  const [filesAllVisible, setFilesAllVisible] = useState(false);

  return (
    <S.Container align="center" justify="space-between">
      <S.Title>{companyName}</S.Title>

      <TransactionsModal
        companyId={companyId}
        open={transactionsModalVisible}
        onCancel={() => setTransactionsModalVisible(false)}
      />

      <DocumentsModal
        companyId={companyId}
        open={documentsModalVisible}
        onCancel={() => setDocumentsModalVisible(false)}
      />

      <LegalModal
        companyId={companyId}
        open={legalModalVisible}
        onCancel={() => setLegalModalVisible(false)}
      />

      <ChatModalViewFiles
        open={filesAllVisible}
        // eslint-disable-next-line
        // @ts-ignore
        files={details?.files || []}
        onCancel={() => setFilesAllVisible(false)}
      />

      <Flex align="center" gap={16}>
        <S.Action onClick={() => setTransactionsModalVisible(true)}>
          Transactions
        </S.Action>

        <S.Action onClick={() => setDocumentsModalVisible(true)}>
          Documents
        </S.Action>

        <S.Action onClick={() => setFilesAllVisible(true)}>Files</S.Action>
      </Flex>
    </S.Container>
  );
};

export default ChatActions;
