import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Group = styled(Flex)<FlexProps>`
  margin-top: 22px;
  padding: 0 30px;
`;

export const Inner = styled(Flex)<FlexProps>`
  flex: 1;
`;

export const Header = styled(Flex)<FlexProps>`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  padding: 0 16px;
`;

export const Footer = styled(Flex)<FlexProps>`
  width: 100%;
  max-width: 350px;
  padding-left: 16px;
  margin-top: 6px;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
`;

export const Delete = styled.a`
  color: ${({ theme }) => theme.colors.error};
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 12px;

  &:hover {
    color: ${({ theme }) => theme.colors.error};
  }
`;

export const Name = styled.strong`
  font-weight: 600;
`;

export const Divider = styled.span`
  font-weight: 500;
`;

export const Role = styled.span`
  font-weight: 500;
`;
