import { Params$AdminBobAccountController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type BobAccount = Schemas.BobAccount;
type Params = Params$AdminBobAccountController_findAll;

type BobAccountsProps = {
  params: Params['parameter'];
  config?: QueryOptions<BobAccount[]>;
};

const useGetBobAccounts = ({ params, config }: BobAccountsProps) => {
  const { data: accounts, ...rest } = useApiClient<Params, BobAccount[]>({
    method: 'AdminBobAccountController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    accounts: accounts || [],
    ...rest,
  };
};

export default useGetBobAccounts;
