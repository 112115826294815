import styled from '@emotion/styled';

import { type FlexProps, Flex } from 'antd';

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  flex: 1;
  padding: 20px 30px;
`;

export const Tabs = styled.div`
  width: 320px;
`;

export const Chat = styled(Flex)<FlexProps>`
  flex: 1;
`;
