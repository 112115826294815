import { Params$AdminBobContactController_create } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClientMutation } from '@hooks';

const useCreateBobContact = () => {
  return useApiClientMutation<
    Params$AdminBobContactController_create,
    Schemas.BobContact
  >({
    method: 'AdminBobContactController_create',
  });
};

export default useCreateBobContact;
