import { PartialDeep } from 'type-fest';

import { Params$AdminDocumentController_updateOneById } from '@api-client/generated/client';

import { useApiClientMutation } from '@hooks';

import { Schemas } from '@api-client/generated/types';

type Params = {
  requestBody: PartialDeep<Schemas.AdminUpdateDocumentDto>;
} & Omit<Params$AdminDocumentController_updateOneById, 'requestBody'>;

const useUpdateDocumentById = () => {
  const file = useApiClientMutation<Params>({
    method: 'AdminDocumentController_updateOneById',
  });

  return file;
};

export default useUpdateDocumentById;
