import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import _ from 'lodash';

import { Flex, Tabs } from 'antd';

import { Scrollbar } from '@components';
import { useGetCompanyById } from '@hooks-api';

import ChatDialogsList from '../../dialogs/ChatDialogsList';
import ChatPanel from '../../panel/ChatPanel';
import ChatPanelActions from '../../panel/ChatPanelActions';
import ChatTabsLabel from '../ChatTabsLabel';
import ChatTabsNotSelectedAlert from '../ChatTabsNotSelectedAlert';

import { useTasksStore } from '@store';

import * as S from './styled';

const ChatTabs: FC = () => {
  const { companyId, id: taskId } = useParams();

  const { company: companyDetails } = useGetCompanyById({
    params: {
      id: companyId!,
    },
    config: {
      enabled: !!companyId,
    },
  });

  const { selectedTask, generalTasks, allTasks, unseenMessagesCount } =
    useTasksStore(
      useShallow(({ tasks }) => ({
        selectedTask: tasks.filter((task) => task.id === taskId)[0],
        generalTasks: tasks.filter((task) => task.isGeneral),
        allTasks: tasks.filter((task) => !task.isGeneral),
        unseenMessagesCount: _.sum(
          tasks.map((task) => task.unseenMessagesCount)
        ),
      }))
    );

  if (!companyId) {
    return (
      <ChatTabsNotSelectedAlert>
        Select a company to start messaging
      </ChatTabsNotSelectedAlert>
    );
  }

  return (
    <S.Container gap={20}>
      <S.Tabs>
        <Tabs
          defaultActiveKey="chats"
          size="large"
          items={[
            {
              key: 'chats',
              label: (
                <ChatTabsLabel
                  label="Chats"
                  unseenMessagesCount={unseenMessagesCount}
                />
              ),
              children: (
                <Scrollbar height="calc(100vh - 290px)" withoutPadding>
                  <Flex gap={15} vertical>
                    <ChatDialogsList type="general" tasks={generalTasks} />
                    <ChatDialogsList tasks={allTasks} />
                  </Flex>
                </Scrollbar>
              ),
            },
          ]}
        />
      </S.Tabs>

      <S.Chat vertical>
        {companyDetails && (
          <ChatPanelActions
            companyId={companyId}
            companyName={companyDetails.name}
            details={selectedTask}
          />
        )}

        {selectedTask && taskId ? (
          <ChatPanel taskId={taskId} details={selectedTask} />
        ) : (
          <ChatTabsNotSelectedAlert>
            Select a chat or task to start messaging
          </ChatTabsNotSelectedAlert>
        )}
      </S.Chat>
    </S.Container>
  );
};

export default ChatTabs;
