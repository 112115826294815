import styled from '@emotion/styled';

import { type DocumentStatus } from './DocumentExpenseStatus';

export const Status = styled.div<{ status: DocumentStatus }>`
  width: 100%;
  background-color: ${({ theme, status }) =>
    status === 'processing'
      ? theme.colors.bgBrightBlue200
      : theme.colors.badgeSuccess};
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  border-radius: 10px 10px 0 0;
  padding: 16px 30px;
`;

export const Header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  border-radius: 10px 10px 0 0;
  padding: 16px 30px;
`;

export const Divider = styled.div`
  width: 2px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.textDisabled};
  margin: 0 8px;
`;

export const StatusName = styled.div<{ status: DocumentStatus }>`
  color: ${({ theme, status }) =>
    status === 'processing' ? theme.colors.primary : theme.colors.success};
  font-weight: 600;
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;
