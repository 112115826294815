import { FC, useEffect, useState } from 'react';

import { Spin } from 'antd';

import { wrapTextLinesInParagraphs } from '@utils';
import { useSocketChat } from '@hooks-socket';

import ChatModalCreateNew from '../../modals/ChatModalCreateNew';
import ChatModalUploadFiles from '../../modals/ChatModalUploadFiles';
import ChatMessagesList from '../../messages/ChatMessagesList';
import ChatPanelHeader from '../ChatPanelHeader';
import ChatPanelFormSend from '../ChatPanelFormSend';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type ChatPanelProps = {
  taskId: string;
  details: Schemas.Task;
};

const ChatPanel: FC<ChatPanelProps> = ({ taskId, details }) => {
  const [modalAttachVisible, setModalAttachVisible] = useState(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [disabledForm, setDisabledForm] = useState(true);

  const {
    messages,
    isLoading,
    createMessage,
    deleteMessage,
    markAsSeenMessage,
  } = useSocketChat({
    companyId: details.companyId,
    taskId,
  });

  useEffect(() => {
    if (newMessage) {
      setNewMessage('');
      setDisabledForm(true);
    }
  }, [taskId]);

  const handleCreateMessage = () => {
    if (newMessage) {
      createMessage(wrapTextLinesInParagraphs(newMessage));
      setNewMessage('');
    }

    setDisabledForm(true);
  };

  const handleChangeMessage = (message: string) => {
    setNewMessage(message);
    setDisabledForm(!message);
  };

  return (
    <S.Panel justify="space-between" vertical>
      <ChatModalCreateNew
        open={modalCreateVisible}
        onCancel={() => setModalCreateVisible(false)}
      />

      <ChatModalUploadFiles
        actionCreateMessage={createMessage}
        selectedId={taskId}
        companyId={details?.companyId}
        open={modalAttachVisible}
        onCancel={() => setModalAttachVisible(false)}
      />

      <ChatPanelHeader
        title={details?.title}
        actions={
          <>
            {/* <S.Action>Mark as unread</S.Action>
            <S.Action onClick={() => setModalCreateVisible(true)}>
              Create new chat
            </S.Action> */}
          </>
        }
      />

      <Spin spinning={isLoading}>
        <ChatMessagesList
          selectedId={taskId}
          dataSource={messages}
          onSeenMessages={markAsSeenMessage}
          onRemove={deleteMessage}
        />
      </Spin>

      <S.Inner>
        <ChatPanelFormSend
          selectedId={taskId}
          value={newMessage}
          disabled={disabledForm || !taskId}
          onAttach={() => setModalAttachVisible(true)}
          onSend={handleCreateMessage}
          onChange={handleChangeMessage}
        />
      </S.Inner>
    </S.Panel>
  );
};

export default ChatPanel;
