import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ChatSidebar, ChatTabs } from '@entities';
import { useGetTasksList } from '@hooks-api';
import { useTasksStore } from '@store';

import * as S from './styled';

const ChatAndTasks: FC = () => {
  const { companyId } = useParams();

  const { fetchTasks } = useTasksStore();

  useGetTasksList({
    params: {
      companyId,
    },
    config: {
      onSuccess: (tasks) => fetchTasks(tasks),
    },
  });

  return (
    <S.Container>
      <ChatSidebar />
      <ChatTabs />
    </S.Container>
  );
};

export default ChatAndTasks;
