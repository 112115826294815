import { Params$AdminBobDaybookController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type BobDaybook = Schemas.BobDaybook;
type Params = Params$AdminBobDaybookController_findAll;

type BobDaybooksProps = {
  params: Params['parameter'];
  config?: QueryOptions<BobDaybook[]>;
};

const useGetBobDaybooks = ({ params, config }: BobDaybooksProps) => {
  const { data: daybooks, ...rest } = useApiClient<Params, BobDaybook[]>({
    method: 'AdminBobDaybookController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    daybooks: daybooks || [],
    ...rest,
  };
};

export default useGetBobDaybooks;
