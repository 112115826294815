import { FC, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { SearchControl } from '@entities';
import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { useGetCompaniesList } from '@hooks-api';

import ChatSidebarList from '../ChatSidebarList';

import * as S from './styled';

const ChatSidebar: FC = () => {
  const [filterSearch, setFilterSearch] = useState(null);

  const { companies, loading } = useGetCompaniesList({
    params: {
      page: 1,
      term: filterSearch!,
    },
  });

  const handleSearch = useDebouncedCallback((value) => {
    setFilterSearch(value);
  }, DEFAULT_TIMEOUT_FOR_SEARCH);

  return (
    <S.Container gap={20} vertical>
      <SearchControl
        placeholder="Look up company"
        onChange={(e) => handleSearch(e.target.value)}
        fitWidth
        isInversedTheme
      />

      <ChatSidebarList companies={companies} loading={loading} />
    </S.Container>
  );
};

export default ChatSidebar;
