import { Params$AdminBobVATController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';

import { useApiClient, type QueryOptions } from '@hooks';

type BobVAT = Schemas.BobVAT;
type Params = Params$AdminBobVATController_findAll;

type BobVatsProps = {
  params: Params['parameter'];
  config?: QueryOptions<BobVAT[]>;
};

const useGetBobVats = ({ params, config }: BobVatsProps) => {
  const { data: vats, ...rest } = useApiClient<Params, BobVAT[]>({
    method: 'AdminBobVATController_findAll',
    params: {
      parameter: params,
    },
    config,
  });

  return {
    vats: vats || [],
    ...rest,
  };
};

export default useGetBobVats;
