import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, App as AppDesign } from 'antd';
import { ThemeProvider } from '@emotion/react';
import { IoProvider } from 'socket.io-react-hook';
import { ErrorBoundary } from '@sentry/react';

import AppRouter from './AppRouter';
import { ErrorFallback } from './ErrorFallback';

import { AccountProvider } from '@context';

import { GlobalStyles } from '@styles/GlobalStyles';
import { config, colors } from '@theme';

const queryClient = new QueryClient();

const App: FC = () => (
  <ErrorBoundary fallback={(errorData) => <ErrorFallback {...errorData} />}>
    <QueryClientProvider client={queryClient}>
      <IoProvider>
        <ConfigProvider prefixCls="ui" iconPrefixCls="ui-icon" theme={config}>
          <ThemeProvider
            // @ts-expect-error-next-line
            theme={{ colors }}
          >
            <AppDesign>
              <GlobalStyles />

              <AccountProvider>
                <AppRouter />
              </AccountProvider>
            </AppDesign>
          </ThemeProvider>
        </ConfigProvider>
      </IoProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
